import Link from 'next/link';

import { PUBLIC_ROUTES } from '../../../routes/routes';
import LeftLogoV2 from './LeftLogoV2';
import RightMenuV2 from './RightMenuV2';

export default function HeaderV2() {
  return (
    <div className="flex justify-between items-end p-3 md:p-6 w-full space-between mb-8">
      <div>
        <Link href={PUBLIC_ROUTES.LANDING}>
          <LeftLogoV2 />
        </Link>
      </div>
      <div>
        <RightMenuV2 />
      </div>
    </div>
  );
}
