'use client';

import React from 'react';

import HeaderV2 from './Header/HeaderV2';

const MainLayoutV2: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="h-full flex flex-col items-center justify-center">
      <HeaderV2 />

      <div className="w-full h-full p-3 md:p-6">{children ?? null}</div>

      {/* 
        Note: Below this is a straight conversion of legacy chakra to tailwind styles.
        I was unable to figure out for sure what this is doing. 
        Best guess is that it served as a pseudo footer to match the height 
        of the nav header. Legacy code below.
       */}
      {/*        
        <Spacer />
        <Box padding={PADDING} w="100%" minH={HEADER_SIZE / 28px}>
          <Box w={LOGO_WIDTH / 320px} h={HEADER_SIZE / 28px} /> // fake logo spacer
        </Box>
       */}
      <div className="p-3 md:p-6 w-full min-h-7">
        <div className="w-80 h-80" />
      </div>
    </div>
  );
};

export default MainLayoutV2;
