'use client';

import { useMemo } from 'react';

import useAppLanguage from '../../../hooks/useAppLanguage';
import FormSelect from '../../Parts/v2/FormSelect';

const RightMenu = () => {
  const { languages, currentLanguage, changeLanguage } = useAppLanguage();

  const options = useMemo(() => {
    return Object.entries(languages).map(([code, { label }]) => ({
      value: code,
      label: label,
    }));
  }, [languages]);

  return (
    <div className="w-28">
      <FormSelect
        options={options}
        defaultValue={currentLanguage.code}
        onChange={(e) => {
          const value = e.currentTarget?.value || e.target.value;
          changeLanguage(value);
        }}
        data-testid="right-menu-language-select"
      />
    </div>
  );
};

export default RightMenu;
